<template>
  <div class="node-container">
    <div class="t-shape-line">

      <el-button
  circle
  @click="removeNode"
  size="mini"
  class="remove-button"
>
  <el-icon :style="{ color: '#ffffff' }" size="small">
    <Minus />
  </el-icon>
</el-button>

      
    </div>
    
    <div class="node-content">
      <el-row class="mtlft">
        <el-col class="for-borders">
          <el-card class="trigger_card" shadow="never">
            <el-row>
              <el-col :span="18" @click="openActionApps(item)">
                <el-row class="header-text">1.Action</el-row>
              </el-col>
              <el-col :span="1">
                <el-row class="header-text">
                  <el-dropdown trigger="click" style="float: right">
                    <el-icon class="more-icon"><More /></el-icon>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item @click="deleteActionStep(item)">
                          <el-icon><Delete /></el-icon>Delete
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </el-row>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <div class="node-action">
      <div class="vertical-line-container">
        <div class="arrow-down">
          <img src="@/assets/vertical-line.png" height="30" width="40" />
        </div>
      </div>
      <div class="button-container">
        <el-tooltip
          class="item"
          effect="dark"
          content="Add action step"
          placement="top-start"
        >
          <el-button
            circle
            @click="addChild"
            size="large"
            style="border: 1px solid #409eff"
          >
            <el-icon :style="{ color: '#409EFF' }" size="large">
              <Plus />
            </el-icon>
          </el-button>
        </el-tooltip>
      </div>
      <div class="arrow-down">
        <img src="@/assets/arrow-down.png" height="30" width="40" />
      </div>
    </div>
    <pathSetup
      v-for="(child, index) in node.children"
      :key="index"
      :node="child"
      @add-node="handleAddNode(child)"
      @remove-node="handleRemoveNode(child)"
    />
  </div>
</template>

<script>
export default {
  name: "pathSetup",
  props: {
    node: Object,
  },
  methods: {
    addChild() {
      this.$emit("add-node", this.node);
    },
    removeNode() {
      this.$emit("remove-node", this.node);
    },
    handleAddNode(child) {
      this.$emit("add-node", child);
    },
    handleRemoveNode(child) {
      this.$emit("remove-node", child);
    },
  },
};
</script>
<style scoped>
.remove-button {
  border: 1px solid #6b52ae; /* Border color */
  background-color: #6b52ae; /* Background color */
  color: #ffffff; /* Text color */
  margin-top: 10px; /* Margin top */
  width: 20px; /* Width */
  height: 20px; /* Height */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 42%; /* Adjusted margin-left */
  position: relative; /* Ensure relative positioning */
  z-index: 1; /* Ensure the button is above other elements */
}

.remove-button:hover {
  background-color: #6b52ae; /* Darker background color on hover */
  border-color: #6b52ae; /* Darker border color on hover */
  z-index: 2; /* Ensure hover state appears above everything */
}
</style>
