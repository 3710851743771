// import axios from "axios";
// import { createStore } from 'vuex';
import appConfig from "../../config/app";
import axios from "./axiosInstance";
// initial state
const initiateState = () => ({
  eventsByAppId: null,
  appById: null,
  updateTriggerAppData: null,
  updateActionAppData: null,
  quickbooksActions: null,
  allFlowsByUserId: null,
  createFlow: null,
  allApps: null,
  automationFlowById: null,
  deletedFlow: null,
  actionDeleteFlow: null,
  renamedFlow: null,
  status: null,
  tokens: null,
  esignsTokens: null,
  // entityList: null,
  appFields: null,
  quickbooksFieldsData: null,
  allConnections: null,
  existingTokens: null,
  getExistingMappings: null,
  corporationList: null,
  TwilioConnection: null,
  actionEventByAppId: null,
  mappedData: null,
  propertyTokens: null,
  actionApps: null,
  workflowsByPagination: null,
  automationFlowsByName: null,
  outlookCredintialsInfo: null,
  outlookTokensInfo: null,
  esignsWebhookResponse: null,
  latestAction: null,
  allActions: null,
  updatedActionConnection: null,
  actionInfo: null,
  actionSchedule: null,
  tokensResponse: null,
  serverUrl: null,
  oauthTokens: null,
  deleteConnection: null,
  updateConnectionName: null,
  saveCredentials: null,
  webhookInstructions: null,
  triggerAppFieldsInMapping: null,
  actionAppFieldsForMapping: null,
  actioStepData: null,
  // googlesheetsdata: null,
  createApp: null,
  // sheetsData: null,
  updateActionAppDataExistingConnection: null,
  fbPageWebhookResponse: null,
  // OneDriveFolders: null,
  // microsoftTeamsChats: null,
  webhookResponse: null,
  // slackChannels: null,
  // slackUsers: null,
  scheduleAutomation: null,
  appFieldsByName: null,
  nestedAppFields: null,
  childAppFields: null,
  refreshedAppFields: null,
  allLogs: null,
  webhookRequest: null,
  filedsRequest: null,
  updatedForm: null,
  actionResponse: null,
  reviewFields: null,
  eachLog: null,
  dependencyFields: null,
  updatedTriggerRecord: null,
});
const state = initiateState();
const getters = {
  getEventsByAppId(state) {
    return state.eventsByAppId;
  },
  getActioStepData(state) {
    return state.actioStepData;
  },
  getAppById(state) {
    return state.appById;
  },
  getUpdateTriggerAppData(state) {
    return state.updateTriggerAppData;
  },
  getUpdateActionAppData(state) {
    return state.updateActionAppData;
  },
  getAllQuickbooksActions(state) {
    return state.quickbooksActions;
  },
  getAllFlowsByUserId(state) {
    return state.allFlowsByUserId;
  },
  getAutomationFlow(state) {
    return state.createFlow;
  },
  getTriggerApps(state) {
    return state.allApps;
  },
  getAutomationFlowById(state) {
    return state.automationFlowById;
  },
  getTokens(state) {
    return state.tokens;
  },
  getDeleteFlow(state) {
    return state.deletedFlow;
  },
  getDeleteActionFlow(state) {
    return state.actionDeleteFlow;
  },
  getRenameFlow(state) {
    return state.renamedFlow;
  },
  getAutomationStatus(state) {
    return state.status;
  },
  getEsignsTokens(state) {
    return state.esignsTokens;
  },
  getAppFields(state) {
    return state.appFields;
  },
  getQuickbooksFields(state) {
    return state.quickbooksFieldsData;
  },
  getAllConnections(state) {
    return state.allConnections;
  },
  getexistingTokens(state) {
    return state.existingTokens;
  },
  getExistingMapping(state) {
    return state.getExistingMappings;
  },
  getTwilioConnection(state) {
    return state.TwilioConnection;
  },
  getActionEventByAppId(state) {
    return state.actionEventByAppId;
  },
  getMappedData(state) {
    return state.mappedData;
  },
  getPropertyTokens(state) {
    return state.propertyTokens;
  },
  getActionApps(state) {
    return state.actionApps;
  },
  getWorkflowsByPagination(state) {
    return state.workflowsByPagination;
  },
  getAutomationFlowBySearch(state) {
    return state.automationFlowsByName;
  },
  getOutlookCredintialsInfo(state) {
    return state.outlookCredintialsInfo;
  },
  getOutlookTokensInfo(state) {
    return state.outlookTokensInfo;
  },
  getEsignsWebhookResponse(state) {
    return state.esignsWebhookResponse;
  },
  getLatestAction(state) {
    return state.latestAction;
  },
  getAllActions(state) {
    return state.allActions;
  },
  getUpdateActionAppDataConnection(state) {
    return state.updatedActionConnection;
  },
  getActionInfo(state) {
    return state.actionInfo;
  },
  getActionSchedule(state) {
    return state.actionSchedule;
  },
  getTokensWithConnection(state) {
    return state.tokensResponse;
  },
  getOauthServerUrl(state) {
    return state.serverUrl;
  },
  getOauthTokens(state) {
    return state.oauthTokens;
  },
  getValidateAndSaveCredentials(state) {
    return state.saveCredentials;
  },
  getDeleteConnection(state) {
    return state.deleteConnection;
  },
  getUpdateConnectionName(state) {
    return state.updateConnectionName;
  },
  getWebhookInstructions(state) {
    return state.webhookInstructions;
  },
  getCreateApp(state) {
    return state.createApp;
  },
  getTriggerAppFieldsInMapping(state) {
    return state.triggerAppFieldsInMapping;
  },
  getActionAppFieldsForMapping(state) {
    return state.actionAppFieldsForMapping;
  },
  getUpdateActionAppDataExistingConnection(state) {
    return state.updateActionAppDataExistingConnection;
  },
  getFbPageWebhookResponse(state) {
    return state.fbPageWebhookResponse;
  },
  getWebhookThirdParty(state) {
    return state.webhookResponse;
  },
  getScheduleAutomation(state) {
    return state.scheduleAutomation;
  },
  getAppFieldsByName(state) {
    return state.appFieldsByName;
  },
  getNestedAppFields(state) {
    return state.nestedAppFields;
  },
  getChildAppFields(state) {
    return state.childAppFields;
  },
  getRefreshedAppFields(state) {
    return state.refreshedAppFields;
  },
  getAllLogs(state) {
    return state.allLogs;
  },
  getWebhookRequest(state) {
    return state.webhookRequest;
  },
  getFieldsRequest(state) {
    return state.filedsRequest;
  },
  getUpdatedForm(state) {
    return state.updatedForm;
  },
  getActionResponse(state) {
    return state.actionResponse;
  },
  getReviewFieldsResponse(state) {
    return state.reviewFields;
  },
  getEachLog(state) {
    return state.eachLog;
  },
  getDependencyFields(state) {
    return state.dependencyFields;
  },
  getUpdatedTriggerRecord(state) {
    return state.updatedTriggerRecord;
  },
};
const mutations = {
  setEventsByAppId(state, data) {
    state.eventsByAppId = data;
  },
  setActioStepData(state, data) {
    state.actioStepData = data;
  },
  setAppById(state, data) {
    state.appById = data;
  },
  setActionEventsByAppId(state, data) {
    state.actionEventByAppId = data;
  },
  setUpdateTriggerAppData(state, data) {
    state.updateTriggerAppData = data;
  },
  setUpdateActionAppData(state, data) {
    state.updateActionAppData = data;
  },
  setAllQuickbooksActions(state, data) {
    state.quickbooksActions = data;
  },
  setAllFlowsByUserId(state, data) {
    state.allFlowsByUserId = data;
  },
  setAutomationFlow(state, data) {
    state.createFlow = data;
  },
  setTriggerApps(state, data) {
    state.allApps = data;
  },
  setPipeLine(state, data) {
    state.piplineInfo = data;
  },
  setUpdatePipeLine(state, data) {
    state.updatePipelineInfo = data;
  },
  setPipelineId(state, data) {
    state.pipelineId = data;
  },
  setAutomationFlowById(state, data) {
    state.automationFlowById = data;
  },
  setTokens(state, data) {
    state.tokens = data;
  },
  setDeleteFlow(state, data) {
    state.deletedFlow = data;
  },
  setActionDeleteFlow(state, data) {
    state.actionDeleteFlow = data;
  },
  setRenameFlow(state, data) {
    state.renamedFlow = data;
  },
  setStatus(state, data) {
    state.status = data;
  },
  setEsignsTokens(state, data) {
    state.esignsTokens = data;
  },
  setAppFields(state, data) {
    state.appFields = data;
  },
  setQuickbooksFields(state, data) {
    state.quickbooksFieldsData = data;
  },
  setConnections(state, data) {
    state.allConnections = data;
  },
  setExistingTokens(state, data) {
    state.existingTokens = data;
  },
  setExistingMapping(state, data) {
    state.getExistingMappings = data;
  },
  setTwilioConnection(state, data) {
    state.TwilioConnection = data;
  },
  setMappedData(state, data) {
    state.mappedData = data;
  },
  setPropertyTokens(state, data) {
    state.propertyTokens = data;
  },
  setActionApps(state, data) {
    state.actionApps = data;
  },
  setActivateFlow(state, data) {
    state.isFlowActivated = data;
  },
  setworkflowPagination(state, data) {
    state.workflowsByPagination = data;
  },
  setworkflowByName(state, data) {
    state.automationFlowsByName = data;
  },
  setOutlookCredintialsInfo(state, data) {
    state.outlookCredintialsInfo = data;
  },
  setOutlookTokensInfo(state, data) {
    state.outlookTokensInfo = data;
  },
  setEsignsWebhook(state, data) {
    state.esignsWebhookResponse = data;
  },
  setLatestAction(state, data) {
    state.latestAction = data;
  },
  setAllActions(state, data) {
    state.allActions = data;
  },
  setUpdateActionAppDataConnection(state, data) {
    state.updatedActionConnection = data;
  },
  setUpdateActionAppDataExistingConnection(state, data) {
    state.updateActionAppDataExistingConnection = data;
  },
  setActionInfo(state, data) {
    state.actionInfo = data;
  },
  setActionSchedule(state, data) {
    state.actionSchedule = data;
  },
  setTokensWithConnection(state, data) {
    state.tokensResponse = data;
  },
  setOauthAppCredintials(state, data) {
    state.serverUrl = data;
  },
  setOauthTokens(state, data) {
    state.oauthTokens = data;
  },
  setLabels(state, data) {
    state.appContent = data;
  },
  setCredentials(state, data) {
    state.saveCredentials = data;
  },
  setDeleteConnection(state, data) {
    state.deleteConnection = data;
  },
  setUpdateConnectionName(state, data) {
    state.updateConnectionName = data;
  },
  setWebhookInstructions(state, data) {
    state.webhookInstructions = data;
  },
  setTriggerAppFieldsInMapping(state, data) {
    state.triggerAppFieldsInMapping = data;
  },
  setActionAppFieldsForMapping(state, data) {
    state.actionAppFieldsForMapping = data;
  },
  setCreateApp(state, data) {
    state.createApp = data;
  },
  setFbPageWebhook(state, data) {
    state.fbPageWebhookResponse = data;
  },
  setWebhookThirdParty(state, data) {
    state.webhookResponse = data;
  },
  setScheduleAutomation(state, data) {
    state.scheduleAutomation = data;
  },
  setAppFieldsByName(state, data) {
    state.appFieldsByName = data;
  },
  setNestedAppFieldsByName(state, data) {
    state.nestedAppFields = data;
  },
  setChildAppFieldsByName(state, data) {
    state.childAppFields = data;
  },
  setRefreshedFields(state, data) {
    state.refreshedAppFields = data;
  },
  setAllLogs(state, data) {
    state.allLogs = data;
  },
  setWebhookRequest(state, data) {
    state.webhookRequest = data;
  },
  setFieldsRequest(state, data) {
    state.filedsRequest = data;
  },
  setUpdatedForm(state, data) {
    state.updatedForm = data;
  },
  setRunActionResponse(state, data) {
    state.actionResponse = data;
  },
  setReviewFields(state, data) {
    state.reviewFields = data;
  },
  setDependencyValues(state, data) {
    state.dependencyFields = data;
  },
  setEachLog(state, data) {
    state.eachLog = data;
  },
  setupdatedTriggerRecord(state, data) {
    state.updatedTriggerRecord = data;
  },
};
const actions = {
  async createAutomationFlow({ commit }, params) {
    let response = null;
    try {
      response = await axios.post(
        `${appConfig.app.connectorAppApi}/automationFlow/create-workflow`,
        params
      );
      commit("setAutomationFlow", response.data);
    } catch (err) {
      commit("setAutomationFlow", err);
    }
  },
  async fetchAutomationFlowById({ commit }, params) {
    try {
      let response = await axios.get(
        `${appConfig.app.connectorAppApi}/automationFlow/get-workflow/${params.id}`
      );
      commit("setAutomationFlowById", response.data);
    } catch (err) {
      commit("setAutomationFlowById", err);
    }
  },
  async fetchAllFlowsByUserId({ commit }) {
    try {
      let response = await axios.get(
        `${appConfig.app.connectorAppApi}/automationFlow/get-workflows-list`
      );
      commit("setAllFlowsByUserId", response.data.data);
    } catch (err) {
      commit("setAllFlowsByUserId", err);
    }
  },
  async fetchAllTriggerApps({ commit }, params) {
    try {
      let response = await axios.get(
        `${appConfig.app.connectorAppApi}/automationFlow/getAllApps/${params}`
      );
      commit("setTriggerApps", response.data);
    } catch (err) {
      commit("setTriggerApps", err);
    }
  },
  async getAllActions({ commit }, params) {
    try {
      let response = await axios.get(
        `${appConfig.app.connectorAppApi}/automationFlow/get-all-actions/${params}`
      );
      commit("setAllActions", response.data);
    } catch (err) {
      commit("setAllActions", err);
    }
  },
  async updateAutomationFlowWithTrigger({ commit }, params) {
    try {
      let response = await axios.post(
        `${appConfig.app.connectorAppApi}/automationFlow/update-automation-with-Trigger-app-info`,
        params
      );
      commit("setUpdateTriggerAppData", response.data);
    } catch (err) {
      commit("setUpdateTriggerAppData", err);
    }
  },
  async updateTriggerAppInfoInFlow({ commit }, params) {
    try {
      let response = await axios.post(
        `${appConfig.app.connectorAppApi}/automationFlow/update-tiggerApp-with-connection/${params.flowId}`,
        params
      );
      commit("setUpdateTriggerAppData", response.data);
    } catch (err) {
      commit("setUpdateTriggerAppData", err);
    }
  },
  async fetchLatestAction({ commit }, params) {
    try {
      let response = await axios.get(
        `${appConfig.app.connectorAppApi}/automationFlow/get-latest-action-of-user-with-automation/${params}`
      );
      commit("setLatestAction", response.data);
    } catch (err) {
      commit("setLatestAction", err);
    }
  },
  async updateActionAppInfoInFlow({ commit }, params) {
    try {
      let response = await axios.post(
        `${appConfig.app.connectorAppApi}/automationFlow/create-action-step`,
        params
      );
      commit("setUpdateActionAppData", response.data);
    } catch (err) {
      commit("setUpdateActionAppData", err);
    }
  },
  async fetchTokens({ commit }, params) {
    let response = null;
    try {
      response = await axios.get(
        `${appConfig.app.connectorAppApi}/oauth/getTokens?code=${params.code}&realmId=${params.realmId}&user_id=${params.user_id}`
      );
      commit("setTokens", response.data);
    } catch (err) {
      commit("setTokens", err);
    }
  },
  async updateActionAppInfoInFlowConnection({ commit }, params) {
    try {
      commit("setUpdateActionAppDataConnection", null);
      let response = await axios.post(
        `${appConfig.app.connectorAppApi}/automationFlow/update-actionApp-data/${params.actionId}`,
        params
      );
      commit("setUpdateActionAppDataConnection", response.data);
    } catch (err) {
      console.log("error while fetching trigger events", err);
    }
  },
  async updateActionwithExistingConnection({ commit }, params) {
    try {
      commit("setUpdateActionAppDataExistingConnection", null);
      let response = await axios.post(
        `${appConfig.app.connectorAppApi}/automationFlow/update-actionApp-data-existing-connection/${params.connection_id}/${params.actionId}`
      );
      commit("setUpdateActionAppDataExistingConnection", response.data);
    } catch (err) {
      console.log("error while fetching trigger events", err);
    }
  },
  async fetchAppFields({ commit }, params) {
    try {
      let response = await axios.get(
        `${appConfig.app.connectorAppApi}/esigns/fetch-esigns-entities-by-id/`,
        { params }
      );
      commit("setAppFields", response.data);
    } catch (err) {
      commit("setAppFields", null);
    }
  },
  async createUpdateMapping({ commit }, params) {
    try {
      commit("setMappedData", null);
      let response = await axios.post(
        `${appConfig.app.connectorAppApi}/automationFlow/create-mapping/${params._id}`,
        params
      );
      commit("setMappedData", response.data);
    } catch (err) {
      console.log("Error while fetching tokens", err);
    }
  },
  async addWebhookInEsigns({ commit }, params) {
    try {
      let response = await axios.post(
        `${appConfig.app.connectorAppApi}/esigns/add-webhook-url/${params.automationId}`,
        params
      );
      commit("setEsignsWebhook", response.data);
    } catch (err) {
      commit("setEsignsWebhook", null);
    }
  },
  async makeAutomationFlowActivate({ commit }, params) {
    try {
      commit("setActivateFlow", null);
      let response = await axios.post(
        `${appConfig.app.connectorAppApi}/automationFlow/activate-deactivate-automationFlow/${params.id}/${params.isActivate}`
      );
      commit("setActivateFlow", response.data);
    } catch (err) {
      console.log("Error while fetching tokens", err);
    }
  },
  async getWorkflowsByPagination({ commit }, params) {
    console.log("paramssmsm", params);
    try {
      let response = await axios.get("/automationFlow/getworkflows", {
        params,
      });
      commit("setworkflowPagination", response.data);
    } catch (err) {
      commit("setworkflowPagination", err);
    }
  },
  async searchWorkflowByName({ commit }, params) {
    try {
      let response = await axios.get(
        `${appConfig.app.connectorAppApi}/automationFlow/search-automation`,
        { params }
      );
      commit("setworkflowByName", response.data);
    } catch (err) {
      commit("setworkflowByName", err);
    }
  },
  async deleteWorkflow({ commit }, workflowId) {
    try {
      console.log("workflowId", workflowId);
      const response = await axios.delete(
        `${appConfig.app.connectorAppApi}/automationFlow/delete-workflow/${workflowId}`
      );
      commit("setDeleteFlow", response.data);
    } catch (error) {
      console.error("An error occurred while deleting the workflow", error);
    }
  },
  async deleteActionStep({ commit }, params) {
    try {
      const response = await axios.delete(
        `${appConfig.app.connectorAppApi}/automationFlow/delete-action-step/${params.action_id}/${params.order}/${params.automationId}`
      );
      commit("setActionDeleteFlow", response.data);
    } catch (error) {
      console.error("An error occurred while deleting the action step", error);
    }
  },
  async fetchLabels({ commit }, appName) {
    try {
      console.log("appName", appName);
      const response = await axios.get(
        `${appConfig.app.connectorAppApi}/automationFlow/fetch-app-content/${appName}`
      );
      commit("setLabels", response.data);
    } catch (error) {
      commit("setLabels", null);
      console.error("An error occurred while fetching the labels", error);
    }
  },
  async validateAndSaveCredentials({ commit }, params) {
    try {
      const response = await axios.post(
        `${appConfig.app.connectorAppApi}/oauth/validate-and-save-credintials`,
        params
      );
      commit("setCredentials", response.data);
    } catch (error) {
      commit("setCredentials", null);
      console.error(
        "An error occurred while verify and saving the credentials",
        error
      );
    }
  },
  async webhookInstructions({ commit }, appName) {
    try {
      const response = await axios.get(
        `${appConfig.app.connectorAppApi}/automationFlow/webhook-instructions/${appName}`
      );
      commit("setWebhookInstructions", response.data);
    } catch (error) {
      commit("setWebhookInstructions", null);
      console.error("An error occurred while fetching the instructions", error);
    }
  },
  async renameAutomationById({ commit }, params) {
    try {
      const response = await axios.post(
        `${appConfig.app.connectorAppApi}/automationFlow/renameAutomation`,
        params
      );
      commit("setRenameFlow", response.data);
    } catch (error) {
      commit("setRenameFlow", null);
      console.error("An error occurred while renaming the workflow", error);
    }
  },
  async fetchActionStep({ commit }, params) {
    try {
      commit("setActioStepData", null);
      const response = await axios.post(
        `${appConfig.app.connectorAppApi}/automationFlow/fetchActionRecord/${params.actionId}`
      );
      commit("setActioStepData", response.data);
    } catch (err) {
      console.log("Error fetching action step data", err);
    }
  },
  async scheduleAction({ commit }, params) {
    try {
      const response = await axios.post(
        `${appConfig.app.connectorAppApi}/automationFlow/add-update-scheduling/${params.item.record_id}`,
        params.parsedDate
      );
      commit("setActionSchedule", response.data);
    } catch (error) {
      commit("setActionSchedule", null);
      console.log("Error scheduling action", error);
    }
  },
  async fetchTriggerAppFieldsForMapping({ commit }, params) {
    try {
      commit("setTriggerAppFieldsInMapping", null);
      const response = await axios.post(
        `${appConfig.app.connectorAppApi}/automationFlow/get-triggerapp-fields-for-mapping/${params.app_id}`,
        params
      );
      commit("setTriggerAppFieldsInMapping", response.data.data);
    } catch (error) {
      console.log("Error fetching Trigger App fields for mapping", error);
    }
  },
  async fetchActionAppFieldsForMapping({ commit }, params) {
    try {
      commit("setActionAppFieldsForMapping", null);
      const response = await axios.post(
        `${appConfig.app.connectorAppApi}/automationFlow/get-actionapp-fields-for-mapping/${params.app_id}`,
        params
      );
      commit("setActionAppFieldsForMapping", response.data.data);
    } catch (error) {
      console.log("Error fetching Action App fields for mapping", error);
    }
  },
  async creationActionStep({ commit }, params) {
    try {
      commit("setUpdateActionAppData", null);
      const response = await axios.post(
        `${appConfig.app.connectorAppApi}/automationFlow/${params.flowId}`,
        params
      );
      commit("setUpdateActionAppData", response.data);
    } catch (err) {
      console.log("Error while creating action step", err);
    }
  },
  async fetchAllActionApps({ commit }, params) {
    try {
      const response = await axios.get(
        `${appConfig.app.connectorAppApi}/automationFlow/getAllApps/${params}`
      );
      commit("setActionApps", response.data);
    } catch (err) {
      commit("setActionApps", err);
    }
  },
  async fetchTriggerEventsByAppId({ commit }, params) {
    try {
      commit("setEventsByAppId", null);
      const response = await axios.get(
        `${appConfig.app.connectorAppApi}/automationFlow/get-tigger-events/${params.app_id}`
      );
      commit("setEventsByAppId", response.data.data);
    } catch (err) {
      console.log("Error while fetching trigger events", err);
    }
  },
  async fetchActionEventsByAppId({ commit }, params) {
    try {
      commit("setActionEventsByAppId", null);
      const response = await axios.get(
        `${appConfig.app.connectorAppApi}/automationFlow/get-action-events/${params.app_id}`
      );
      commit("setActionEventsByAppId", response.data.data);
    } catch (err) {
      console.log("Error while fetching action events", err);
    }
  },
  async getActionById({ commit }, params) {
    try {
      commit("setActionInfo", null);
      const response = await axios.get(
        `${appConfig.app.connectorAppApi}/automationFlow/get-action-step-by-id/${params}`
      );
      commit("setActionInfo", response.data);
    } catch (err) {
      console.log("Error while fetching action info", err);
    }
  },
  async fetchAppById({ commit }, params) {
    try {
      commit("setAppById", null);
      const response = await axios.get(
        `${appConfig.app.connectorAppApi}/automationFlow/get-app/${params.id}`
      );
      commit("setAppById", response.data);
    } catch (err) {
      console.log("Error while fetching app by ID", err);
    }
  },
  async getPropertyTokens({ commit }, params) {
    try {
      commit("setPropertyTokens", null);
      const response = await axios.post(
        `${appConfig.app.connectorAppApi}/property/get-tokens`,
        params
      );
      commit("setPropertyTokens", response.data);
    } catch (err) {
      console.log("Error while fetching property tokens", err);
    }
  },
  async automationFlowByStatus({ commit }, params) {
    try {
      const response = await axios.get(
        `${appConfig.app.connectorAppApi}/automationFlow/getAutomationFlowsByStatus`,
        { params }
      );
      commit("setStatus", response.data);
    } catch (error) {
      commit("setStatus", null);
    }
  },
  async saveOutlookCredintials({ commit }, params) {
    try {
      const response = await axios.post(
        `${appConfig.app.connectorAppApi}/outlook/save-outlook-tokens/${params.user_id}`,
        params.outlookCredintials
      );
      commit("setOutlookCredintialsInfo", response.data);
    } catch (error) {
      commit("setOutlookCredintialsInfo", null);
      console.error("Error while saving Outlook credentials", error);
    }
  },
  async fetchTokensOutlook({ commit }, params) {
    try {
      const response = await axios.post(
        `${appConfig.app.connectorAppApi}/oauth/get-access-token/${params.user_id}`,
        params
      );
      commit("setOutlookTokensInfo", response.data);
    } catch (error) {
      commit("setOutlookTokensInfo", null);
      console.error("Error while fetching Outlook tokens", error);
    }
  },
  async action({ commit }, params) {
    try {
      const response = await axios.get(
        `${appConfig.app.connectorAppApi}/automationFlow/get-action-events/${params}`
      );
      commit("setAllQuickbooksActions", response.data.data);
    } catch (err) {
      commit("setAllQuickbooksActions", null);
      console.error("Error while fetching Quickbooks actions", err);
    }
  },
  async esignsTokensInfo({ commit }, params) {
    try {
      const response = await axios.post(
        `${appConfig.app.connectorAppApi}/oauth/tokens-from-esigns`,
        params
      );
      commit("setEsignsTokens", response.data);
    } catch (err) {
      commit("setEsignsTokens", null);
      console.error("Error while fetching e-signs tokens", err);
    }
  },
  async quickbooksFields({ commit }, params) {
    try {
      const response = await axios.get(
        `${appConfig.app.connectorAppApi}/automationFlow/fetch-qb-fields/${params}`
      );
      commit("setQuickbooksFields", response.data);
    } catch (err) {
      commit("setQuickbooksFields", null);
      console.error("Error while fetching Quickbooks fields", err);
    }
  },
  async fetchExisistingConnections({ commit }, params) {
    try {
      const response = await axios.get(
        `${appConfig.app.connectorAppApi}/automationFlow/get-existing-connections-of-app-with-user/${params.appName}`
      );
      commit("setConnections", response.data);
    } catch (err) {
      commit("setConnections", null);
    }
  },
  async fetchExisistingTokens({ commit }, params) {
    try {
      const response = await axios.get(
        `${appConfig.app.connectorAppApi}/users/get-existing-tokens`,
        { params }
      );
      commit("setExistingTokens", response.data);
    } catch (err) {
      commit("setExistingTokens", null);
      console.error("Error while fetching existing tokens", err);
    }
  },
  async getExistingMappingApi({ commit }, params) {
    try {
      const response = await axios.get(
        `${appConfig.app.connectorAppApi}/automationFlow/get-existing-mapping`,
        { params }
      );
      commit("setExistingMapping", response.data);
    } catch (err) {
      commit("setExistingMapping", null);
      console.error("Error while fetching existing mapping", err);
    }
  },
  async getTwilioTokens({ commit }, params) {
    try {
      const response = await axios.post(
        `${appConfig.app.connectorAppApi}/twilio/save-twilio-tokens`,
        params
      );
      commit("setTwilioConnection", response.data);
    } catch (err) {
      commit("setTwilioConnection", null);
      console.error("Error while fetching Twilio tokens", err);
    }
  },
  async getOauthAppServerUrl({ commit }, params) {
    try {
      const response = await axios.get(
        `${appConfig.app.connectorAppApi}/oauth/find-oauthapp-credintials/${params}`
      );
      commit("setOauthAppCredintials", response.data);
    } catch (err) {
      commit("setOauthAppCredintials", null);
      console.error(
        "An error occurred while fetching OAuth app credentials",
        err
      );
    }
  },
  async getOauthTokens({ commit }, params) {
    try {
      const response = await axios.post(
        `${appConfig.app.connectorAppApi}/oauth/get-tokens-by-exchanging-authorization-code`,
        params
      );
      commit("setOauthTokens", response.data);
    } catch (err) {
      commit("setOauthTokens", null);
      console.error("An error occurred while fetching OAuth tokens", err);
    }
  },
  async deleteConnection({ commit }, params) {
    try {
      const response = await axios.delete(
        `${appConfig.app.connectorAppApi}/oauth/delete-connection/${params}`
      );
      commit("setDeleteConnection", response.data);
    } catch (err) {
      commit("setDeleteConnection", null);
      console.error("An error occurred while deleting connection", err);
    }
  },
  async updateConnectionName({ commit }, params) {
    try {
      const response = await axios.post(
        `${appConfig.app.connectorAppApi}/oauth/update-connection-name`,
        params
      );
      commit("setUpdateConnectionName", response.data);
    } catch (err) {
      commit("setUpdateConnectionName", null);
      console.error("An error occurred while updating connection name", err);
    }
  },
  async addWebhookInFaceBookPage({ commit }, params) {
    try {
      const response = await axios.post(
        `${appConfig.app.connectorAppApi}/automationFlow/add-webookurl-in-facebookpage/${params.automationId}`
      );
      commit("setFbPageWebhook", response.data);
    } catch (err) {
      commit("setFbPageWebhook", null);
      console.error(
        "An error occurred while adding webhook to Facebook page",
        err
      );
    }
  },
  async registerWebhookInThirdParty({ commit }, params) {
    try {
      const response = await axios.post(
        `${appConfig.app.connectorAppApi}/automationFlow/add-webookurl-in-thirdParty`,
        params
      );
      commit("setWebhookThirdParty", response.data);
    } catch (err) {
      commit("setWebhookThirdParty", null);
      console.error(
        "An error occurred while registering webhook in third party",
        err
      );
    }
  },
  async fetchAppFieldsByName({ commit }, params) {
    try {
      const response = await axios.post(
        `${appConfig.app.connectorAppApi}/automationFlow/fetch-fields-by-appName`,
        params
      );
      commit("setAppFieldsByName", response.data);
    } catch (err) {
      commit("setAppFieldsByName", null);
      console.error("An error occurred while fetching app fields by name", err);
    }
  },
  async scheduleAutomation({ commit }, params) {
    try {
      const response = await axios.post(
        `${appConfig.app.connectorAppApi}/cron/schedule-automation-cronjob`,
        params
      );
      commit("setScheduleAutomation", response.data);
    } catch (err) {
      commit("setScheduleAutomation", null);
      console.error("An error occurred while scheduling automation", err);
    }
  },
  async fetchNestedAppFieldsByName({ commit }, params) {
    try {
      const response = await axios.post(
        `${appConfig.app.connectorAppApi}/automationFlow/fetch-nested-fields-by-appName`,
        params
      );
      commit("setNestedAppFieldsByName", response.data);
    } catch (err) {
      commit("setNestedAppFieldsByName", null);
      console.error(
        "An error occurred while fetching nested app fields by name",
        err
      );
    }
  },
  async fetchChildFieldsByName({ commit }, params) {
    try {
      const response = await axios.post(
        `${appConfig.app.connectorAppApi}/automationFlow/fetch-child-fields-by-appName`,
        params
      );
      commit("setChildAppFieldsByName", response.data);
    } catch (err) {
      commit("setChildAppFieldsByName", null);
      console.error(
        "An error occurred while fetching child fields by name",
        err
      );
    }
  },
  async fetchRefreshingFields({ commit }, params) {
    try {
      const response = await axios.post(
        `${appConfig.app.connectorAppApi}/automationFlow/refresh-fields`,
        params
      );
      commit("setRefreshedFields", response.data);
    } catch (err) {
      commit("setRefreshedFields", null);
      console.error("An error occurred while fetching refreshing fields", err);
    }
  },
  async fetchAllLogs({ commit }, params) {
    try {
      const response = await axios.post(
        `${appConfig.app.connectorAppApi}/logs/fetch-logs-by-automation`,
        params
      );
      commit("setAllLogs", response.data);
    } catch (err) {
      commit("setAllLogs", null);
    }
  },
  async getLogDetailsByKey({ commit }, params) {
    try {
      const response = await axios.post(
        `${appConfig.app.connectorAppApi}/logs/filter-logs-by-key`,
        params
      );
      commit("setEachLog", response.data);
    } catch (err) {
      commit("setEachLog", null);
    }
  },
  async findTriggerRecords({ commit }, params) {
    console.log("paramsmsms", params);
    try {
      const response = await axios.get(
        `${appConfig.app.connectorAppApi}/automationFlow/find-webhook-request-in-automation/${params}`
      );
      commit("setWebhookRequest", response.data);
    } catch (err) {
      commit("setWebhookRequest", null);
    }
  },
  async findFields({ commit }, params) {
    try {
      const response = await axios.get(
        `${appConfig.app.connectorAppApi}/automationFlow/find-fields-by-automation/${params.automationId}/${params.actionId}`
      );
      commit("setFieldsRequest", response.data);
    } catch (err) {
      commit("setFieldsRequest", null);
    }
  },
  async updateFormDataToAction({ commit }, params) {
    try {
      const response = await axios.post(
        `${appConfig.app.connectorAppApi}/automationFlow/update-action-with-form`,
        params
      );
      commit("setUpdatedForm", response.data);
    } catch (err) {
      commit("setUpdatedForm", null);
    }
  },
  async runAction({ commit }, params) {
    try {
      const response = await axios.post(
        `${appConfig.app.connectorAppApi}/runAction/run-action`,
        params
      );
      commit("setRunActionResponse", response.data);
    } catch (err) {
      commit("setRunActionResponse", null);
    }
  },
  async reviewFields({ commit }, params) {
    try {
      const response = await axios.post(
        `${appConfig.app.connectorAppApi}/automationFlow/review-fields`,
        params
      );
      commit("setReviewFields", response.data);
    } catch (err) {
      commit("setReviewFields", null);
    }
  },
  async fillDependencyValues({ commit }, params) {
    try {
      const response = await axios.post(
        `${appConfig.app.connectorAppApi}/automationFlow/fetch-dependency-fields`,
        params
      );
      commit("setDependencyValues", response.data);
    } catch (err) {
      commit("setDependencyValues", null);
    }
  },
  async updateTriggerRecord({ commit }, params) {
    try {
      const response = await axios.post(
        `${appConfig.app.connectorAppApi}/automationFlow/update-trigger-data-record`,
        params
      );
      commit("setupdatedTriggerRecord", response.data);
    } catch (err) {
      commit("setupdatedTriggerRecord", null);
    }
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
