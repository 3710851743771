import { createApp } from "vue";
import "element-plus/dist/index.css";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import router from "./router/index";
import App from "./App.vue";
import store from "./store/index";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import "./assets/styles/app.scss";
import "bootstrap/dist/css/bootstrap.css";
import bootstrap from "bootstrap/dist/js/bootstrap.bundle.js";
import mitt from "mitt";
import VueTimezoneSelect from "vue-timezone-select";
import authentication from "./middlewares/authentication.js";
const emitter = mitt();
const app = createApp(App);
app.config.globalProperties.emitter = emitter;
app.use("store", store);
app.use(router);
app.use(store);
app.use(ElementPlus);
app.use(bootstrap);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
router.beforeEach((to, from, next) => {
  const publicPages = ["/"];
  const authRequired = !publicPages.includes(to.path);
  let isAuthenticated = authentication.isAuthenticated();
  if (authRequired && !isAuthenticated) {
    next("/");
  } else {
    next();
  }
});
app.component("vue-timezone-select", VueTimezoneSelect);
app.mount("#app");
