<template>
  <div>
    <h1>Connector</h1>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  async mounted() {
    this.extractQueryFromUrl();
  },
  methods: {
    async extractQueryFromUrl() {
      let query = this.$route.query;
      localStorage.setItem("extractCode", JSON.stringify(query));
      window.close();
    },
  },
};
</script>
