<template>
  <el-tab-pane label="App & Event" name="appEvent" key="appEvent">
    <el-card
      class="selected-app"
      width="100%"
      style="box-shadow: 0 20px 10px -20px rgba(0, 0, 0, 0.45) inset"
    >
      <el-button circle class="app-image" disabled>
        <img :src="appInfo.app_id.logo" width="33" height="32" /> </el-button
      >&nbsp; &nbsp;
      <span style="float: left; font-size: 18px">{{
        appInfo.app_id.app_name_label
      }}</span>
      <el-button
        type="plain"
        class="change-button"
        @click="openAllActionApp(action)"
        float="right"
      >
        Change
      </el-button>
    </el-card>
    <span style="color: red"> * </span>
    &nbsp;
    <span class="Event">Event</span>
    <el-select
      v-model="appInfo.event_id"
      placeholder="Add a record"
      filterable
      style="width: 100%; border: 1px solid rgb(183, 183, 185); margin-bottom: 10px"
      class="select-dropdown"
      size="large"
      @change="showFields"
    >
      <el-option
        v-for="(event, index) in appInfo.app_id.events"
        :key="index"
        :value="event._id"
        :label="event.eventName"
        :disabled="event.enabled === 'false'"
        class="custom-option"
      >
        <span>
          <span>{{ event.eventName }}-{{ event.eventDescription }}</span>
        </span>
      </el-option>
    </el-select>
  </el-tab-pane>
</template>
<script>
export default {
  props: {
    step: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      appInfo: this.step,
    };
  },
  computed: {},
  mounted() {
    this.formatEventsArray();
  },
  methods: {
    async formatEventsArray() {
      if (this.appInfo.app_id.trigger_events.length) {
        //temporary function//
        this.appInfo.app_id.events = this.appInfo.app_id.trigger_events;
      } else {
        this.appInfo.app_id.events = this.appInfo.app_id.action_events;
      }
    },
    async openAllActionApp(action) {
      this.emitter.emit("openActionAppsList", action);
    },
  },
};
</script>
