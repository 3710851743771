// import axios from "axios";
import apiClient from "axios";
import appConfig from "../../config/app";
import store from "../index";

//axios instance
const axios = apiClient.create({
  baseURL: `${appConfig.app.connectorAppApi}`,
  headers: {
    "Content-Type": "application/json",
  },
});
// Request interceptor to add the token to headers
axios.interceptors.request.use(
  (config) => {
    let user = JSON.parse(localStorage.getItem("user-info"));
    let accessToken = user.tokens.accessToken.token;
    const token = accessToken;
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  (error) => Promise.reject(error)
);
// Response interceptor to handle errors
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      return refreshTokenAndRetry(error);
    }
    return Promise.reject(error);
  }
);
// Function to refresh the token and retry the original request
async function refreshTokenAndRetry(error) {
  try {
    let user = JSON.parse(localStorage.getItem("user-info"));
    let refreshToken = user.tokens.refreshToken.token;
    let payload = {
      token: refreshToken,
    };
    await store.dispatch("user/newAccessToken", payload);
    let newAccessToken = user.tokens.accessToken.token;
    error.config.headers.Authorization = newAccessToken;
    return axios(error.config);
  } catch (refreshError) {
    if (refreshError.response.status === 401) {
      this.$router.push(this.$route.query.redirect || "/");
    }
    return Promise.reject(refreshError);
  }
}
export default axios;
