<template>
  <el-row class="drawer_slide">
    <el-col :span="4">
      <el-button circle class="drawer-logo">
        <img :src="appInfo.app_id.logo" width="39" height="39" />
      </el-button>
    </el-col>
    <br />
    <el-col :span="20">
      <div class="drawer-title">
        {{ appInfo.app_id.app_name_label }}
      </div>
      <div class="drawer-title-p">You can oversee all your secure connections.</div>
    </el-col>
  </el-row>
</template>
<script>
export default {
  props: {
    step: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      appInfo: this.step,
    };
  },
};
</script>
