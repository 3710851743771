import axios from "axios";
import appConfig from "@/config/app";

// initial state
const initiateState = () => ({
  signupResponse: null,
  loginResponse: null,
});

const state = initiateState();

const getters = {
  getLoginResponse(state) {
    return state.loginResponse;
  },
  getUserSignupResponse(state) {
    return state.signupResponse;
  },
};
const mutations = {
  setUserData(state, data) {
    state.signupResponse = data;
  },
  setLoginResponse: (state, data) => {
    state.loginResponse = data;
  },
  setNewAccessToken: (state, data) => {
    let userInfo = JSON.parse(localStorage.getItem("user-info"));
    userInfo.tokens.accessToken = data.data.accessToken;
    localStorage.setItem("user-info", JSON.stringify(userInfo));
  },
};

const actions = {
  async userSignup({ commit }, params) {
    try {
      let response = await axios.post(
        `${appConfig.app.connectorAppApi}/users/signup`,
        params
      );
      commit("setUserData", response.data);
    } catch (err) {
      commit("setUserData", err.response);
    }
  },
  async userLogin({ commit }, params) {
    try {
      const response = await axios.post(
        `${appConfig.app.connectorAppApi}/users/login`,
        params
      );
      commit("setLoginResponse", response.data);
    } catch (err) {
      commit("setLoginResponse", err.response);
    }
  },
  async newAccessToken({ commit }, params) {
    try {
      const response = await axios.post(
        `${appConfig.app.connectorAppApi}/users/refresh-token`,
        params
      );
      commit("setNewAccessToken", response.data);
    } catch (err) {
      commit("setNewAccessToken", err.response);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
