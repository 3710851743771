export default {
  app: {
    connectorAppApi: process.env.VUE_APP_CONNECTOR_API
      ? process.env.VUE_APP_CONNECTOR_API
      : process.env.VUE_APP_CONNECTOR_API_LOCAL,
    esignsOauthUrl: process.env.VUE_APP_ESIGNS
      ? process.env.VUE_APP_ESIGNS
      : process.env.VUE_APP_ESIGNS_LOCAL,
    redirectUri: process.env.VUE_APP_REDIRECT_URI
      ? process.env.VUE_APP_REDIRECT_URI
      : process.env.VUE_APP_REDIRECT_URI_LOCAL,
  },
};
