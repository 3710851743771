<template>
  <div
    v-loading="loading"
    element-loading-spinner="el-loading-spinner"
    element-loading-background="rgba(0, 0, 0, 0.1)"
  >
    <el-container style="height: 100vh; display: flex; flex-direction: column">
      <drawerHeader :step="automationRecord"></drawerHeader>
      <!-- Tabs -->
      <el-row>
        <el-tabs v-model="activeTab" style="flex-grow: 1" @tab-click="handleTabs">
          <appEventComponent :step="automationRecord"></appEventComponent>
          <connectionsComponent
            :step="automationRecord"
            v-if="automationRecord.app_id.isAuthenticating"
          ></connectionsComponent>
          <el-tab-pane
            label="Trigger"
            class="trigger_size"
            name="trigger"
            key="trigger"
            :disabled="automationRecord.app_connection_id ? false : true"
            v-if="checkDomainFields()"
          >
            <renderFields
              v-if="automationRecord.form_data"
              :key="automationRecord.app_connection_id"
              :fields="automationRecord.form_data"
              :automationInfo="automationRecord"
              :isTrigger="true"
            />
          </el-tab-pane>
          <el-tab-pane label="Test" key="test" name="test" :disabled="isTestTabActive">
            <el-button
              shadow="always"
              size="large"
              type="primary"
              :loading="submitLoading"
              style="
                width: 100%;
                font-size: 17px;
                height: 40px;
                border: none;
                background-color: #f754a2;
                margin-bottom: 10px;
                margin-top: 10px;
              "
              @click="findRecords"
              class="custom-button btn-clr"
            >
              <span>Find Records</span>
            </el-button>
            <responseDisplayTable
              v-if="tableData"
              :response="tableData"
              :key="responseData"
            ></responseDisplayTable>
          </el-tab-pane>
          <el-button
            shadow="always"
            size="large"
            type="primary"
            :loading="submitLoading"
            style="
              width: 42%;
              font-size: 17px;
              height: 48px;
              border: none;
              background-color: #f754a2;
              margin-bottom: 10px;
              margin-top: 10px;
              position: fixed;
              bottom: 0;
            "
            @click="goToNextTab"
            class="connectButton-propsObject"
          >
            <span>Continue</span>
          </el-button>
        </el-tabs>
      </el-row>
    </el-container>
  </div>
</template>
<script>
import store from "@/store/index";
import { mapGetters } from "vuex";
import renderFields from "@/components/connectorApp/renderFields.vue";
import appEventComponent from "./appEventComponent.vue";
import connectionsComponent from "./connectionsComponent.vue";
import responseDisplayTable from "./responseDisplayTable.vue";
import drawerHeader from "./drawerHeader.vue";
export default {
  components: {
    renderFields,
    appEventComponent,
    connectionsComponent,
    responseDisplayTable,
    drawerHeader,
  },
  props: {
    automationInfo: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      updateConnectionDialog: false,
      deleteConnectionDialog: false,
      updateConnectionsData: false,
      textareaOfDialog: "",
      loading: false,
      activeTab: this.$route.params.tab ? this.$route.params.tab : "appEvent",
      webhookRequest: {},
      automationRecord: null,
      tableData: null,
      event: null,
      isTestTabActive: this.displayTestTab(),
    };
  },
  computed: {
    ...mapGetters("globalApis", [
      "getAllConnections",
      "getOauthServerUrl",
      "getOauthTokens",
      "getAppFieldsByName",
      "getUpdateTriggerAppData",
      "getWebhookRequest",
      "getWebhookInstructions",
      "getReviewFieldsResponse",
    ]),
  },
  created() {
    this.emitter.on("bindDepencyFieldsInParent", (data) => {
      this.listenEventDependencyFields(data);
    });
  },
  async beforeMount() {
    await this.fetchAutomationFlowById();
  },
  methods: {
    checkDomainFields() {
      let appInfo = this.automationInfo.app_id;
      let macthedEvent = appInfo.trigger_events.find(
        (event) => event._id == this.automationInfo.event_id
      );
      if (macthedEvent && macthedEvent.domain_fields) {
        return true;
      } else {
        return false;
      }
    },
    displayTestTab() {
      let isPresent = this.checkDomainFields();
      if (isPresent && this.automationInfo.form_data) {
        return false;
      } else if (this.automationInfo.app_connection_id) {
        return false;
      } else {
        return true;
      }
    },
    async fetchAutomationFlowById() {
      this.automationRecord = this.automationInfo;
      this.$router.push({
        name: "workflow",
        params: {
          automationFlowId: this.$route.params.automationFlowId,
          actionId: this.$route.params.actionId,
          tab: this.activeTab,
          step: this.$route.params.step,
        },
      });
    },
    async deleteConnectionEvent() {
      let connectionId = this.connectionModel._id;
      let response = await this.deleteConnection(connectionId);
      if (response.status) {
        let indexValue = await this.connections.findIndex(
          (obj) => obj._id == connectionId
        );
        this.connections.splice(indexValue, 1);
        this.connectionModel = null;
        this.deleteConnectionDialog = false;
        this.$notify.success({
          title: "Success",
          message: response.message,
        });
      } else {
        this.$notify.error({
          title: "Error",
          message: response.message,
        });
      }
    },
    async updateConnectionNameEvent() {
      let name = this.textareaOfDialog;
      let connectionId = this.connectionModel._id;
      let response = await this.updateConnectionName(name, connectionId);
      if (response.status) {
        let matchedId = await this.connections.find((obj) => obj._id == connectionId);
        matchedId.name = name;
        this.updateConnectionDialog = false;
        this.$notify.success({
          title: "Success",
          message: response.message,
        });
      } else {
        this.$notify.error({
          title: "Error",
          message: response.message,
        });
      }
    },
    async updateConnection() {
      let connectionId = this.connectionModel._id;
      let userId = this.automationRecord.user_id;
      let response = await this.updateConnection(connectionId, userId);
      if (response.status) {
        this.$notify.success({
          title: "Success",
          message: response.message,
        });
      } else {
        this.$notify.error({
          title: "Error",
          message: response.message,
        });
      }
    },
    async SaveExisistingConnection() {
      await this.updateTriggerAppInfo();
      this.activeTab = "trigger";
    },
    async openTriggerAllApps() {
      this.emitter.emit("openTriggerAppsList", false);
    },
    async updateTriggerAppInfo() {
      let params = {
        app_id: this.automationRecord.app_id._id,
        app_connection_id: this.automationRecord.app_connection_id
          ? this.automationRecord.app_connection_id
          : null,
        _id: this.automationRecord._id,
        event_id: this.automationRecord.event_id ? this.automationRecord.event_id : null,
        webhookRequest: this.automationRecord.webhookRequest
          ? this.automationRecord.webhookRequest
          : null,
        form_data: this.automationRecord.form_data
          ? this.automationRecord.form_data
          : null,
      };
      this.loading = true;
      await store.dispatch("globalApis/updateAutomationFlowWithTrigger", params);
      this.loading = false;
    },
    async saveTriggerEventData() {
      await this.updateTriggerAppInfo();
      await this.registerWebhook();
      this.activeTab = "test";
    },
    async registerWebhook() {
      let webhookBody = {
        appName: this.automationRecord.app_id.app_name_key,
        event: this.automationRecord.form_data,
        automationId: this.automationRecord._id,
        isCron: false,
      };
      this.loading = true;
      await store.dispatch("globalApis/registerWebhookInThirdParty", webhookBody);
      this.loading = false;
    },
    async findRecords() {
      try {
        this.loading = true;
        await store.dispatch("globalApis/findTriggerRecords", this.automationRecord._id);
        this.loading = false;
        this.tableData = this.getWebhookRequest.data.structuredData;
      } catch (err) {
        return err;
      }
    },
    // async getNestedFields(ParentValue, parentIndex) {
    //   let params = {
    //     connection_id: this.automationRecord.app_connection_id,
    //     data: ParentValue,
    //     index: parentIndex,
    //     _id: this.automationRecord._id,
    //   };
    //   try {
    //     this.loading = true;
    //     await store.dispatch("fetchNestedAppFieldsByName", params);
    //     this.loading = false;
    //     if (this.getNestedAppFields && this.getNestedAppFields.data) {
    //       this.automationRecord.requiredIds.labels[
    //         parentIndex
    //       ] = this.getNestedAppFields.data;
    //     }
    //   } catch (error) {
    //     return error;
    //   }
    // },
    // async getChildFields(childValue, childIndex, parentIndex) {
    //   let params = {
    //     connection_id: this.automationRecord.app_connection_id,
    //     data: childValue,
    //     childIndex: childIndex,
    //     parentIndex: parentIndex,
    //   };
    //   try {
    //     this.loading = true;
    //     await store.dispatch("fetchChildFieldsByName", params);
    //     this.loading = false;
    //     if (this.getChildAppFields && this.getChildAppFields.data) {
    //       this.fieldsObject.labels[parentIndex].children[
    //         childIndex + 1
    //       ].value = this.getChildAppFields.data;
    //     }
    //   } catch (error) {
    //     return error;
    //   }
    // },
    async fetchWebhookInstructions(appName) {
      this.loading = true;
      await this.$store.dispatch("globalApis/webhookInstructions", appName);
      this.loading = false;
      this.webhookData = this.getWebhookInstructions.data;
    },
    async goToNextTab() {
      if (this.activeTab == "appEvent") {
        await this.updateTriggerAppInfo();
        if (this.automationRecord.app_id.isAuthenticating) {
          this.activeTab = "account";
        } else {
          if (this.checkDomainFields()) {
            this.activeTab = "trigger";
          } else {
            this.activeTab = "test";
          }
        }
      } else if (this.activeTab == "account") {
        if (this.checkDomainFields()) {
          await this.updateTriggerAppInfo();
          this.activeTab = "trigger";
        } else {
          this.saveTriggerEventData();
        }
      } else if (this.activeTab == "trigger") {
        this.saveTriggerEventData();
        // this.activeTab = "test";
      } else if (this.activeTab == "test") {
        await this.updateTriggerData();
        this.activeTab = "appEvent";
      }
    },
    async updateTriggerData() {
      let payload = this.getWebhookRequest.data.details;
      this.loading = true;
      await store.dispatch("globalApis/updateTriggerRecord", payload);
      this.loading = false;
    },
    async selectEvent() {
      await this.updateTriggerAppInfo();
    },
    async fetchAppFields() {
      let eventFields = await this.automationRecord.app_id.trigger_events.find(
        (event) => event._id == this.automationRecord.event_id
      );
      let params = {
        fieldsSet: eventFields,
        connectionId: this.automationRecord.app_connection_id,
        automationId: this.automationRecord._id,
        appName: this.automationRecord.app_id.app_name_key,
      };
      this.loading = true;
      await store.dispatch("globalApis/reviewFields", params);
      this.loading = false;
      this.automationRecord.form_data = this.getReviewFieldsResponse.data.fieldsSet.domain_fields;
    },
    async selectConnection() {
      await this.updateTriggerAppInfo();
    },
    listenEventDependencyFields(data) {
      this.automationRecord.form_data = data;
    },
    async handleTabs(tab) {
      this.activeTab = tab.props.name;
      this.$router.push({
        name: "workflow",
        params: {
          automationFlowId: this.$route.params.automationFlowId,
          actionId: this.$route.params.actionId,
          tab: this.activeTab,
          step: this.$route.params.step,
        },
      });
      if (tab.props.name == "trigger") {
        if (!this.automationRecord.form_data) {
          await this.fetchAppFields();
        }
      }
    },
  },
};
</script>
<style>
.ui-card {
  margin: 20px;
}

.ui-content {
  margin: 20px;
}

.wide-input {
  width: 260px;
}

.info-section {
  color: darkgray;
  font-size: 13px;
  font-weight: 100;
}

.info-text {
  color: darkgray;
  font-size: 13px;
  font-weight: 100;
}

.info-link {
  color: cornflowerblue;
}

.drawer-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  color: #1b4554;
  position: fixed;
  height: 90px;
  top: 0;
  left: 50;
  right: 0;
  z-index: 999;
}

.drawer {
  top: 0;
  position: fixed;
  display: flex;
  align-items: flex-start;
  background-color: #ffffff;
  padding: 1%;
  height: 80px;
  border: 1px solid #e5e5e5;
  z-index: 1;
  right: 0;
  width: 35%;
}

.drawer-title-p {
  color: #909399;
  font-size: 14px;
  padding-top: 2%;
}

.drawer-title {
  color: #303133;
  font-size: 18px;
}

.drawer-logo {
  border-radius: 0px;
  border: none;
  display: flex;
  align-items: center;
}

.select-box .el-input__inner {
  width: 340px;
}

.oauth-connect-button {
  border-radius: 0px;
}

.drawer-card-header {
  color: #303133;
  font-size: 16px;
}

.drawer-footer {
  bottom: 0;
  right: 0;
  left: 0;
}

.button-connect-drawer {
  border-radius: 0px;
}

.textStyle {
  font-size: 18px;
}

.drawer_slide {
  top: 50px;
  position: fixed;
  display: flex;
  align-items: flex-start;
  background-color: #ffffff;
  padding: 1%;
  height: 80px;
  border: 1px solid #e5e5e5;
  /* z-index: 1; */
  right: 0;
  width: 45%;
}

.container {
  margin-top: 1%;
  width: 100%;
}
</style>
