import { createStore } from "vuex";
import userModule from "./modules/user";
import globalApis from "./modules/store";

const store = createStore({
  modules: {
    user: userModule,
    globalApis: globalApis,
  },
  strict: false,
});
export default store;
