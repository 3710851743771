<template>
  <div
    id="top"
    :style="{
      backgroundColor: '#f0f0f0', // Light gray background color
      backgroundImage: 'radial-gradient(circle, #ccc 1px, transparent 1px)', // Background image with dots
      backgroundSize: '15px 15px', // Size of the dots
      minHeight: containerMinHeight, // Set the min-height dynamically
    }"
  >
    <div
      v-loading="loading"
      element-loading-spinner="el-loading-spinner"
      element-loading-background="rgba(0, 0, 0, 0.7)"
    >
      <el-dialog
        title="Schedule your action"
        v-model="scheduleActionModel"
        draggable
        v-loading="modalLoading"
        :element-loading-text="loadingText"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :before-close="destroyCreateModal"
        :custom-class="['light-theme-dialog']"
      >
        <div>
          <el-date-picker
            v-model="scheduleModel"
            type="datetime"
            placeholder="Select date and time"
          >
          </el-date-picker>
          <el-button type="primary" @click="saveSchedule(item)" style="float: right"
            >Save</el-button
          >
        </div>
      </el-dialog>
      <el-dialog
        title="Choose Action App"
        class="doc-modal create-workflow-popup"
        v-model="actionApp"
        :visible="actionApp"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :before-close="destroyCreateModal"
      >
        <el-row>
          <el-input
            class="button-search"
            v-model="searchActionApps"
            placeholder="Search App"
            size="large"
          >
            <template #prefix>
              <el-icon class="el-input__icon">
                <Search />
              </el-icon>
            </template>
          </el-input>
        </el-row>

        <el-row :gutter="16" class="all-apps">
          <el-col
            :xs="8"
            :sm="8"
            :md="8"
            :lg="8"
            :xl="8"
            v-for="app in filteredActionApps"
            :key="app._id"
          >
            <div
              class="app-card-container"
              @click="selectActionApp(app)"
              @mouseenter="showBackground"
              @mouseleave="hideBackground"
            >
              <div class="app-details">
                <div class="image_style">
                  <img class="app-logo" :src="app.logo" />
                </div>

                <span class="app-name">{{ app.app_name_label }}</span>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-dialog>
      <el-dialog
        title="Choose Trigger App"
        v-model="triggerapp"
        class="doc-modal create-workflow-popup"
        v-loading="modalLoading"
        :element-loading-text="loadingText"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        :visible="triggerapp"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :before-close="destroyCreateModal"
      >
        <el-row>
          <el-input
            class="button-search"
            v-model="searchApps"
            placeholder="Search App"
            size="large"
          >
            <template #prefix>
              <el-icon class="el-input__icon">
                <Search />
              </el-icon>
            </template>
          </el-input>
        </el-row>
        <!-- App list -->
        <el-row :gutter="20" class="all-apps">
          <el-col
            :xs="8"
            :sm="8"
            :md="8"
            :lg="8"
            :xl="8"
            v-for="app in filteredApps"
            :key="app._id"
          >
            <div
              class="app-card-container"
              @click="selectTriggerApp(app)"
              @mouseenter="showBackground"
              @mouseleave="hideBackground"
            >
              <div class="app-details">
                <div class="image_style">
                  <img class="app-logo" :src="app.logo" />
                </div>

                <span class="app-name">{{ app.app_name_label }}</span>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-dialog>
      <el-container>
        <el-header class="header-configure-workflow">
          <el-row class="top-bar" align="middle">
            <el-col :xl="1" :lg="1" :md="1" :sm="1" :xs="1" class="logo-container">
              <el-tooltip effect="dark" content="Go Back" placement="right">
                <el-button @click="goBack" class="button-back" type="text">
                  <span
                    aria-hidden="true"
                    data-testid="iconContainer"
                    class="css-1brxyr-Icon--arrowBigLeft--animate--30x30--neutral100"
                    style="background-color: white"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      height="30"
                      width="30"
                      size="30"
                      color="neutral100"
                      name="arrowBigLeft"
                    >
                      <path
                        fill="#2D2E2E"
                        d="M7.14 13H20v-2H7.14l5.04-6H9.56l-5.87 7 5.87 7h2.62l-5.04-6Z"
                      ></path>
                      <path
                        fill="#2D2E2E"
                        d="M7.14 13H20v-2H7.14l5.04-6H9.56l-5.87 7 5.87 7h2.62l-5.04-6Z"
                      ></path>
                    </svg>
                  </span>
                </el-button>
              </el-tooltip>
            </el-col>

            <el-col :xl="3" :lg="3" :md="3" :sm="3" :xs="3">
              <el-form class="form-title">
                <el-input
                  v-if="automationInfo"
                  v-model="automationInfo.name"
                  placeholder="Enter Entity Title"
                  :class="getIsMobile ? 'nameSty' : 'input-type-one entity-title-input'"
                  size="medium"
                ></el-input>
                <p class="error" v-if="automationInfo && !automationInfo.name"></p>
              </el-form>
            </el-col>
            <el-col :xl="1" :lg="1" :md="1" :sm="1" :xs="1" class="switch-container">
              <el-tooltip
                effect="dark"
                content="In-Activate / Activate"
                placement="right"
              >
                <el-switch
                  v-if="automationInfo"
                  v-model="automationInfo.isActivated"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  @click="activateAutomationFlow"
                >
                </el-switch>
              </el-tooltip>
            </el-col>
          </el-row>
        </el-header>
      </el-container>
      <el-col :xl="1" :lg="1" :md="1">
        <div class="fixed-sidebar">
          <Sidebar_workflow :class="{ 'sidebar-visible': isVisible }"></Sidebar_workflow>
        </div>
      </el-col>
      <div
        :style="{
          position: 'relative',

          transform: `translate(${translateX}px, ${translateY}px) scale(${scale})`,
          transition: 'transform 0.3s ease', // Add transition for smooth animation
        }"
      >
        <VueDraggableResizable
          :x="x"
          :y="y"
          :w="w"
          :style="transformStyle"
          @dragging="(x, y) => onDrag(x, y, item)"
        >
          <br />
          <!-- TRIGGER-FLOW -->
          <el-row class="row-2">
            <el-col :lg="5" class="for-border">
              <el-card class="trigger_card" shadow="never">
                <el-row shadow="always">
                  <el-col :span="5">
                    <img
                      v-if="automationInfo && automationInfo.app_id.logo"
                      :src="automationInfo.app_id.logo"
                      width="33"
                      height="32"
                    />

                    <img src="@/assets/flowchart.png" v-else width="33" height="32" />
                  </el-col>
                  <el-col :span="16" @click="openTriggerApps">
                    <div style="display: flex; flex-direction: column">
                      <el-row class="header-text"> 1.Trigger </el-row>
                      <el-row class="header-p"> when a certain event occurs </el-row>
                    </div>
                  </el-col>
                </el-row>
              </el-card>
            </el-col>

            <el-col>
              <div class="vertical-line-container">
                <div class="arrow-down">
                  <img src="@/assets/vertical-line.png" height="30" width="40" />
                </div>
              </div>

              <div class="button-container">
                <el-tooltip class="item" content="Add action step" placement="top-start">
                  <el-button
                    circle
                    @click="addActionStep(1, 'trigger')"
                    shadow="always"
                    size="large"
                    style="border: 1px solid #409eff"
                  >
                    <el-icon :style="{ color: '#409EFF' }" size="large">
                      <Plus />
                    </el-icon>
                  </el-button>
                </el-tooltip>
              </div>
              <div class="arrow-down">
                <img src="@/assets/arrow-down.png" height="30" width="40" />
              </div>
            </el-col>
          </el-row>

          <!-- ACTION-FLOW -->
          <el-row class="row-2" v-for="(item, index) in actionSteps" :key="index">
            <el-col :lg="5" class="for-border">
              <el-card class="trigger_card" shadow="never">
                <el-row>
                  <el-col :span="5" @click="openActionApps(item)">
                    <img
                      v-if="item.app_id"
                      :src="item.app_id.logo"
                      width="33"
                      height="32"
                    />
                    <img v-else src="@/assets/flowchart.png" width="33" height="32" />
                  </el-col>

                  <el-col :span="18" @click="openActionApps(item)">
                    <el-row class="header-text"> {{ index + 1 }}.Action </el-row>
                    <el-row class="header-p"> Do This </el-row>
                  </el-col>

                  <el-col :span="1">
                    <el-row class="header-text">
                      <el-dropdown trigger="click" style="float: right">
                        <el-icon class="more-icon">
                          <More />
                        </el-icon>

                        <template #dropdown>
                          <el-dropdown-menu>
                            <el-dropdown-item @click="deleteActionStep(item)">
                              <el-icon>
                                <Delete />
                              </el-icon>
                              Delete</el-dropdown-item
                            >
                          </el-dropdown-menu>
                        </template>
                      </el-dropdown>
                    </el-row>
                  </el-col>
                </el-row>
              </el-card>
            </el-col>
            <el-col>
              <div class="vertical-line-container">
                <div class="arrow-down">
                  <img src="@/assets/vertical-line.png" height="30" width="40" />
                </div>
              </div>
              <div
                class="button-container"
             
              >
                <!-- Render this block if item.app_id?.app_name_key equals path -->
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="Add action step"
                  placement="top-start"
                >
                  <el-button
                    circle
                    @click="addActionStep(index, 'action')"
                    size="large"
                    style="border: 1px solid #409eff"
                  >
                    <el-icon :style="{ color: '#409EFF' }" size="large">
                      <Plus />
                    </el-icon>
                  </el-button>
                </el-tooltip>
              </div>
              <!-- <div class="button-container" v-else>
             
                <el-button
                  circle
                  @click="addRootNode"
                  size="large"
                  style="border: 1px solid #409eff"
                >
                  <el-icon :style="{ color: '#409EFF' }" size="large">
                    <Plus />
                  </el-icon>
                </el-button>
              </div> -->

              <div class="arrow-down">
                <img src="@/assets/arrow-down.png" height="30" width="40" />
              </div>
            </el-col>
          </el-row>

          <div class="page-container">
            <div class="root-node-container">
              <path-setup
                v-for="(node, index) in nodes"
                :key="index"
                :node="node"
                @add-node="addNode"
                @remove-node="removeNode"
              />
            </div>
          </div>
        </VueDraggableResizable>
      </div>
      <div class="controls" :style="{ top: controlsPosition }">
        <el-tooltip effect="dark" content="Zoom In" placement="top">
          <button @click="zoomIn">+</button>
        </el-tooltip>
        <span class="zoom-level">{{ zoomPercentage }}%</span>
        <el-tooltip effect="dark" content="Zoom Out" placement="top">
          <button @click="zoomOut">-</button>
        </el-tooltip>
        <el-tooltip effect="dark" content="Fit To Screen" placement="top">
          <button @click="fitToScreen">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              height="24"
              width="24"
              size="24"
              name="arrowZoom"
            >
              <path
                fill="#2D2E2E"
                d="m16 2-2 2h4.59l-4.3 4.29 1.42 1.42L20 5.41V10l2-2V2h-6ZM2 2v6l2 2V5.41l4.29 4.3 1.42-1.42L5.41 4H10L8 2H2ZM20 18.59l-4.29-4.3-1.42 1.42 4.3 4.29H14l2 2h6v-6l-2-2v4.59ZM9.71 15.71l-1.42-1.42L4 18.59V14l-2 2v6h6l2-2H5.41l4.3-4.29Z"
              ></path>
            </svg>
          </button>
        </el-tooltip>
      </div>
    </div>
    <el-drawer
      v-model="isTriggerAppDrawerOpened"
      custom-class="custom-dialog"
      :append-to-body="true"
      :destroy-on-close="true"
      @close="onDrawerClose"
      style="background-color: #f8f9fa"
    >
      <triggerDrawer :automationInfo="automationInfo"> </triggerDrawer>
    </el-drawer>
    <el-drawer
      v-model="actionDailogExist"
      custom-class="custom-dialog"
      :append-to-body="true"
      :destroy-on-close="true"
      style="background-color: #f8f9fa"
    >
      <actionDrawer :propsObject="actionData" :automationInfo="automationInfo">
      </actionDrawer>
    </el-drawer>
  </div>
</template>

<script>
import store from "@/store/index";
import triggerDrawer from "./triggerDrawer.vue";
import { mapGetters } from "vuex";
import actionDrawer from "@/components/connectorApp/actionDrawer";
import { ref } from "vue";
import Sidebar_workflow from "@/layouts/Sidebar_workflow.vue";
import VueDraggableResizable from "vue-draggable-resizable";
import pathSetup from "@/components/connectorApp/pathSetup.vue";
export default {
  name: "workflows-addEditWorkflow",
  components: {
    triggerDrawer,
    actionDrawer,
    Sidebar_workflow,
    VueDraggableResizable,
    pathSetup,
  },

  data() {
    return {
      nodes: [],
      triggerapp: false,
      actionApp: false,
      x: 0,
      w: "100%",
      y: 0,
      fitToScreenApplied: false,
      translateX: 0,
      translateY: 0,
      scale: 1,
      index: 0,
      globalAutomationId: null,
      openMappingDialog: false,
      searchApps: "",
      searchActionApps: "",
      isTriggerAppSelected: false,
      selectedApp: null,
      allApps: [],
      loading: false,
      isTriggerAppDrawerOpened: false,
      actionDailogExist: ref(false),
      actionSteps: [],
      hardCheck: true,
      actionData: null,
      isActivate: true,
      actionStepInfo: null,
      quickbooksUrl: "https:connect.esigns.io/connector/workflow",
      isLinkCopied: false,
      scheduleActionModel: false,
      scheduleModel: null,
      authUrl: null,
      automationInfo: null,
    };
  },
  created() {
    this.emitter.on("openTriggerAppsList", (data) => {
      this.listenEventTrigger(data);
    });
    this.emitter.on("openActionAppsList", (data) => {
      this.listenEventAction(data);
    });
  },
  computed: {
    transformStyle() {
      if (this.fitToScreenApplied) {
        return {
          transform: `translate(${this.translateX}px, ${this.translateY}px) scale(${this.scale})`,
        };
      } else {
        return {};
      }
    },
    zoomPercentage() {
      return Math.round(this.scale * 100);
    },
    filteredApps() {
      if (!this.searchApps) return this.allApps;
      const searchTerm = this.searchApps.toLowerCase();
      return this.allApps.filter((app) =>
        app.app_name.toLowerCase().includes(searchTerm)
      );
    },
    filteredActionApps() {
      if (!this.searchActionApps) return this.getActionApps.data;
      const query = this.searchActionApps.toLowerCase();
      return this.getActionApps.data.filter((app) =>
        app.app_name_label.toLowerCase().includes(query)
      );
    },
    containerMinHeight() {
      // Calculate the minimum height based on the number of action steps
      const baseHeight = 600; // Minimum height in pixels
      const stepHeight = 150; // Height of each action step in pixels
      return `${baseHeight + this.actionSteps.length * stepHeight}px`;
    },

    controlsPosition() {
      // Calculate the left position based on the number of action events
      const baseTop = 680; // Base bottom position in pixels
      const eventHeight = 1; // Height of each action event in pixels
      return `${baseTop + this.actionSteps.length * eventHeight}px`;
    },
    ...mapGetters("globalApis", [
      "getAutomationFlowById",
      "getTriggerApps",
      "getActionApps",
      "getisFlowActivated",
      "getLatestAction",
      "getAllActions",
      "getDeleteActionFlow",
      "getUpdateActionAppData",
    ]),
  },
  async beforeMount() {
    await this.fetchAutomationFlowById();
    await this.getAllTriggerAppsList();
    await this.getAllActionAppsList();
    await this.showActionSteps();
  },
  methods: {
    addRootNode() {
      const newNode = this.createNode("Root");
      this.nodes.push(newNode); // Use this.nodes.push to add the new node
      if (this.nodes.length > 11) {
        this.zoomOut();
      }
    },
    addNode(parentNode) {
      const newNode = this.createNode(`Node ${parentNode.children.length + 1}`);
      parentNode.children.push(newNode); // Ensure parentNode.children is an array
    },
    createNode(name) {
      return {
        name: name,
        children: [], // Initialize children as an empty array
      };
    },
    removeNode(nodeToRemove) {
      const findAndRemoveNode = (nodes, nodeToRemove) => {
        const index = nodes.indexOf(nodeToRemove);
        if (index !== -1) {
          nodes.splice(index, 1);
        } else {
          nodes.forEach((node) => findAndRemoveNode(node.children, nodeToRemove));
        }
      };
      findAndRemoveNode(this.nodes, nodeToRemove);
    },

    openTriggerApps() {
      if (this.automationInfo.event_id) {
        this.isTriggerAppDrawerOpened = true;
      } else {
        this.triggerapp = true;
      }
      this.$route.params.step = "triggerStep";
      this.$router.push({
        name: "workflow",
        params: {
          automationFlowId: this.automationInfo._id,
          actionId: this.$route.params.actionId,
          tab: this.$route.params.tab,
          step: "triggerStep",
        },
      });
      console.log("push to query", this.$route.params);
    },
    closeDrawer() {
      this.isTriggerAppDrawerOpened = false;
    },
    openActionApps(item) {
      if (item.event_id) {
        this.actionData = item;
        this.actionDailogExist = true;
      } else {
        this.actionApp = true;
      }
      // this.$route.params.step = 'action'
      this.$router.push({
        name: "workflow",
        params: {
          automationFlowId: this.automationInfo._id,
          actionId: item._id,
          tab: this.$route.params.tab,
          step: "actionStep",
        },
      });
    },
    listenEventAction(item) {
      item.actionApp = true;
      this.actionDailogExist = false;
    },
    listenEventTrigger(value) {
      this.isTriggerAppDrawerOpened = value;
      this.triggerapp = true;
    },
    onDrag(x, y) {
      if (this.fitToScreenApplied) {
        this.x = 0;
        this.y = 0;
        this.fitToScreenApplied = false;
      } else {
        this.x = x;
        this.y = y;
      }
    },

    zoomIn() {
      const maxScale = 1; // Maximum scale (100%)
      const zoomFactor = 1.1; // Factor to zoom in by (10% increase)

      // Only zoom in if the current scale is less than the maximum scale
      if (this.scale < maxScale) {
        // Calculate the new scale
        const newScale = this.scale * zoomFactor;

        // Apply the new scale only if it does not exceed the maximum scale
        this.scale = Math.min(newScale, maxScale);
      }
    },
    zoomOut() {
      this.scale *= 0.8; // Decrease scale by 20%
    },

    fitToScreen() {
      const maxActionSteps = 10; // Maximum number of action steps to fit in a single vision
      const scaleFactor = Math.min(1, maxActionSteps / this.actionSteps.length);

      // Apply the new scale factor
      this.scale = scaleFactor;

      // Reset translation and other properties if needed
      this.translateX = 0;
      this.translateY = 0;
      this.x = 0;
      this.y = 0;
      this.fitToScreenApplied = true;
      this.$nextTick(() => {
        const container = document.getElementById("top");
        if (container) {
          const offset = -50; // Adjust this value as needed to move 10px before the top
          const containerPosition =
            container.getBoundingClientRect().top + window.pageYOffset + offset;
          window.scrollTo({ top: containerPosition, behavior: "smooth" });
        }
      });
    },
    async onDrawerClose() {
      this.isTriggerAppDrawerOpened = false;
    },
    goBack() {
      this.$confirm("Are you sure to Exit?", "Warning", {
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        type: "warning",
      })
        .then(async () => {
          this.$router.replace({
            path: `/connector/all-workflows`,
          });
        })
        .catch(() => {
          this.$message({
            title: "Warning",
            message: "Exit canceled",
            type: "warning",
          });
        });
    },
    async fetchAutomationFlowById() {
      this.globalAutomationId = this.$route.params.automationFlowId;
      this.loading = true;
      await store.dispatch("globalApis/fetchAutomationFlowById", {
        id: this.globalAutomationId,
      });
      this.loading = false;
      this.automationInfo = this.getAutomationFlowById.data;
      if (!this.automationInfo.app_id) {
        this.automationInfo["app_id"] = { logo: null };
        this.automationInfo["app_id"]["trigger_events"] = null;
        this.automationInfo["app_id"]["_id"] = null;
      }
    },
    async getAllTriggerAppsList() {
      let eventType = "Trigger";
      this.loading = true;
      await store.dispatch("globalApis/fetchAllTriggerApps", eventType);
      this.loading = false;
      if (this.getTriggerApps && this.getTriggerApps.data) {
        this.allApps = this.getTriggerApps.data;
      }
    },
    async selectTriggerApp(app) {
      this.triggerapp = false;
      this.isTriggerAppDrawerOpened = true;
      this.selectedApp = app;
      this.automationInfo.app_id.logo = app.logo;
      this.automationInfo.app_id._id = app._id;
      this.automationInfo.app_id.app_name_label = app.app_name_label;
      this.automationInfo.app_id.app_name_key = app.app_name_key;
      this.automationInfo.app_id.isAuthenticating = app.isAuthenticating;
      this.isTriggerAppSelected = true;
      if (app && app.trigger_events) {
        this.automationInfo.app_id.trigger_events = app.trigger_events;
      }
    },
    async getAllActionAppsList() {
      let eventType = "Action";
      this.loading = true;
      await store.dispatch("globalApis/fetchAllActionApps", eventType);
      this.loading = false;
    },
    checkIsConnectionExists() {
      let actionsLength = this.actionSteps.length;
      let latestAction = this.actionSteps[actionsLength - 1];
      if (latestAction && latestAction.app_connection_id) {
        return true;
      } else if (this.getAutomationFlowById.data.app_connection_id) {
        return true;
      } else {
        return false;
      }
    },
    async addActionStep(index, value) {
      let isExisists = this.checkIsConnectionExists();
      let latestAction;
      if (value == "action") {
        latestAction = index > 0 ? this.actionSteps[index] : this.actionSteps[0];
        index = index + 2;
      }
      if (isExisists) {
        let newActionStep = {
          app_id: null,
          automationId: this.automationInfo._id,
          event_id: null,
          app_connection_id: null,
          form_data: null,
          response_object: null,
          filter_rules: [
            {
              rules: [
                {
                  field: {
                    label: "",
                    key: "",
                    value: "",
                    appName: "",
                    logo: "",
                    automationId: "",
                    actionId: "",
                    isMapping: false,
                  },
                  condition: "",
                  value: "",
                },
              ],
            },
          ],
          order: index,
          previous_action: latestAction ? latestAction._id : null,
        };
        this.loading = true;
        await store.dispatch("globalApis/updateActionAppInfoInFlow", newActionStep);
        this.loading = false;
        if (this.getUpdateActionAppData && this.getUpdateActionAppData.success) {
          newActionStep = this.getUpdateActionAppData.data;
          // this.actionSteps.push(newActionStep);
          this.actionSteps.splice(index - 1, 0, newActionStep);
          this.$notify({
            title: "Success",
            message: "Action step added successfully",
            type: "success",
          });
          this.$router.push({
            name: "workflow",
            params: {
              automationFlowId: this.automationInfo._id,
              actionId: newActionStep._id,
            },
          });
        }
      } else {
        this.$notify({
          title: "Warning",
          message: "Connection was not established in previous step",
          type: "warning",
        });
      }
    },
    async selectActionApp(app) {
      let actionId = this.$route.params.actionId;
      let matchedAction = this.actionSteps.find((action) => action._id == actionId);
      this.actionData = matchedAction;
      this.actionData["app_id"] = app;
      this.actionDailogExist = true;
      this.actionApp = false;
    },

    async showActionSteps() {
      if (this.getAutomationFlowById.data && this.getAutomationFlowById.data._id) {
        await store.dispatch(
          "globalApis/getAllActions",
          this.getAutomationFlowById.data._id
        );
        if (this.getAllActions.data && this.getAllActions.data.length) {
          this.actionSteps = this.getAllActions.data;
        }
        // for (let item of allEvents) {
        //   let newActionStep = {
        //     _id: item._id,
        //     app_id: item.app_id,
        //     automationId: this.automationInfo._id,
        //     event_id: item.event_id,
        //     app_connection_id: item.app_connection_i,
        //     form_data: item.form_data,
        //     response_object: item.response_object,
        //   }
        //   let newActionStep = {
        //     id: this.actionSteps.length,
        //     name: "Action Step" + this.actionSteps.length,
        //     allApps: this.getActionApps.data,
        //     selectedActionEvent: item.event_id,
        //     multiSelectAction: true,
        //     multiActionSelect: false,
        //     multipleActionEventConnected: item.app_connection_id ? true : false,
        //     multipletoBeProcessed: false,
        //     app_connection_id: item.app_connection_id ? item.app_connection_id : null,
        //     appInfo: item.app_id,
        //     record_id: item._id,
        //     fieldsObject: item.requiredIds,
        //     fields: item.form_data,
        //     responseData: item.response_object ? item.response_object : null,
        //   };
        //   this.actionSteps.push(newActionStep);
        // }
      }
    },
    async activateAutomationFlow() {
      this.loading = true;
      await store.dispatch(
        "globalApis/updateAutomationFlowWithTrigger",
        this.automationInfo
      );
      this.loading = false;
    },
    /* ******************************************************************************* */
    async copyTheTextInTheInputBox() {
      try {
        await navigator.clipboard.writeText(this.quickbooksUrl);
        this.isLinkCopied = true;
        this.$message.success("Link copied successfully");
      } catch (err) {
        console.log("the error is", err);
      }
    },
    getCircleNumber(index) {
      return index + 2;
    },
    deleteActionStep(item) {
      this.$confirm("Are you sure you want to delete this step?")
        .then(async () => {
          this.loading = true;
          await store.dispatch("globalApis/deleteActionStep", {
            action_id: item._id,
            order: item.order,
            automationId: item.automationId,
          });
          this.loading = false;
          if (this.getDeleteActionFlow.success) {
            let matchedActionIndex = this.actionSteps.findIndex(
              (action) => action._id == item._id
            );
            this.actionSteps.splice(matchedActionIndex, 1);
            this.$notify({
              title: "Success",
              message: this.getDeleteActionFlow.message,
              type: "success",
            });
          } else {
            this.$notify({
              title: "Error",
              message: this.getDeleteActionFlow.message,
              type: "error",
            });
          }
        })
        .catch(() => {});
    },
    async saveSchedule(item) {
      const dateObject = new Date(this.scheduleModel);
      const parsedDate = {
        date: dateObject.getDate(),
        day: dateObject.getDay(), // 0 (Sunday) through 6 (Saturday)
        month: dateObject.getMonth(), // 0 (January) through 11 (December)
        year: dateObject.getFullYear(),
        hours: dateObject.getHours(),
        minutes: dateObject.getMinutes(),
        seconds: dateObject.getSeconds(),
        timezoneOffset: dateObject.getTimezoneOffset(),
        timezone: dateObject.toString().match(/\((.*?)\)/)[1], // Extract timezone from the string
      };
      await store.dispatch("globalApis/scheduleAction", { parsedDate, item });
    },
  },
};
</script>

<style>
.app-card-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* Align items with space in-between */
  margin-bottom: 10px;
  /* Adjust margin as needed */
  transition: box-shadow 0.3s ease;
  /* Add transition for smooth effect */
  padding: 10px;
}

.app-card-container:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  /* Shadow effect on hover */
  background-color: #f8f9fa;
  /* Background color on hover */
  padding: 9px;
  border-radius: 3px;
}

.image_style {
  align-items: center;
  justify-content: center;
  background-color: rgb(255, 253, 249);
  border: 1px solid rgb(232, 231, 228);
  border-radius: 3px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-right: 10%;
}

.app-details {
  display: flex;
  align-items: center;
}

.app-logo {
  width: 30px;
  /* adjust width as needed */
  height: 30px;
  /* adjust height as needed */
  margin-right: 10px;
  /* adjust margin as needed */
}

.app-name {
  flex-grow: 1;
  /* Other styles for app name */
}

.hover-content {
  padding: 20px;
  /* Adjust padding as needed */
}

.controls {
  position: fixed; /* or absolute */
  bottom: 0;
  left: 100px; /* Adjust as needed */
  display: flex;
  align-items: center;
}

.controls button {
  margin-right: 10px;
  padding-left: 8px;
  padding-right: 8px;
  border: none;
  border-radius: 4px;
  background-color: #4caf50;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.controls button:hover {
  background-color: #45a049;
}

.zoom-level {
  margin-right: 10px;
  font-size: 16px;
  color: #555;
  /* Adjust text color as needed */
}

.background-container {
  min-height: 100vh; /* Ensure the container covers at least the full viewport height */
  position: relative;
  overflow: hidden; /* Hide overflow content beyond the viewport height */
}

.controls {
  margin-bottom: 20px;
}

.root-node-container {
  display: flex;
  /* margin-top:5%; */
  /* flex-wrap: wrap; */
}

.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.button-container {
  margin-bottom: 20px;
}

/* .root-node-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
} */

.node-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-text {
  font-size: 14px;
}

.header-p {
  font-size: 12px;
}

.for-borders {
  border-radius: 5px;
  border-color: #6b52ae;
  border-style: solid;
  background-color: #6b52ae;
  color: rgb(45, 46, 46);
  transition: opacity 150ms ease-out, box-shadow 150ms ease-out,
    border-color 300ms ease-in-out, background-color 300ms ease-in-out;
  position: relative;
  opacity: 1;
}

.for-borders:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.node-content {
  margin-bottom: 10px;
}

.node-action {
}

.t-shape-line {
  width: 100%;
  height: 40px;
  position: relative;
  margin-bottom: 10px;
}

.t-shape-line::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 2px;
  height: 100%;
  background-color: #b3bbcd;
}

.t-shape-line::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #b3bbcd;
}

.mtlft {
  margin-left: 10%;
}
</style>
