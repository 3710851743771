<template>
  <div
    v-loading="loading"
    element-loading-spinner="el-loading-spinner"
    element-loading-background="rgba(0, 0, 0, 0.7)"
  >
    <el-form label-position="top" style="width: 100%">
      <el-form-item
        v-for="(field, index) in allFields"
        :key="index"
        :label="field.label"
        :prop="field.key"
      >
        <template v-if="field.type === 'select'">
          <el-select
            v-model="field.model"
            :placeholder="field.placeHolder"
            size="large"
            clearable
            style="width: 100%"
            @change="fillDepencies(field, allFields)"
          >
            <el-option
              v-for="option in field.value"
              :key="option.id"
              :label="option.name"
              :value="option.id"
            />
          </el-select>
        </template>
        <template v-if="field.type === 'text'">
          <div class="artboard">
            <el-input
              class="tag-textarea"
              v-model="field.model"
              clearable
              @focus="showCard(field)"
              @blur="handleDependecyFields(field, allFields)"
              type="text"
              placeholder="Please Enter Text Or Map Values"
              :autosize="{ minRows: 1, maxRows: 5 }"
            />
          </div>
        </template>
        <template v-if="field.type === 'number'">
          <div class="artboard">
            <el-input
              class="tag-textarea"
              v-model="field.model"
              clearable
              @focus="showCard(field)"
              @blur="handleDependecyFields(field, allFields)"
              type="number"
              placeholder="Please Enter Number Or Map Values"
              :autosize="{ minRows: 1, maxRows: 5 }"
            />
          </div>
        </template>
        <template v-else-if="field.type === 'date'">
          <el-date-picker
            v-model="field.model"
            type="datetime"
            :placeholder="field.placeHolder"
          >
            <template #suffix>
              <el-tag
                v-for="(tag, index) in field.mappedFields"
                :key="index"
                closable
                @close="removeTag(index, field)"
              >
                {{ tag.value }}
              </el-tag>
            </template>
          </el-date-picker>
        </template>
        <template v-else-if="field.type === 'array'">
          <el-button @click="addRow(field)">Add Row</el-button>
          <div
            v-for="(row, rowIndex) in field.rows"
            :key="rowIndex"
            style="margin-bottom: 10px"
          >
            <renderFields
              :fields="row"
              :mappingFields="mappingFields"
              :actionInfo="actionInfo"
              :automationInfo="automationInfo"
            ></renderFields>
            <el-button @click="removeRow(field, rowIndex)" type="danger"
              >Remove Row</el-button
            >
          </div>
        </template>
        <span class="clrl">{{ field.description }}</span>
        <div class="tags-container">
          <div class="artboard">
            <el-tag
              class="button-with-icon"
              v-for="(tag, index) in field.mappedFields"
              :key="index"
              closable
              @close="removeTag(index, field)"
            >
              <img :src="tag.logo" alt="logo" class="button-icon" />
              <span class="clrf"> {{ tag.label }}</span> :
              <span class="clrl">
                <template v-if="tag && !tag.showFullText">
                  {{
                    tag.value && tag.value.length > 25
                      ? tag.value.slice(0, 25) + "..."
                      : tag.value
                  }}
                  <span v-if="tag.value && tag.value.length > 25">
                    <a @click="toggleText(tag)" class="clr">More</a>
                  </span>
                </template>
                <template v-else>
                  <span class="clrf"> {{ tag.label }}</span> :
                  <span class="clrl">{{ tag.value }}</span>
                  <a @click="toggleText(tag)" class="clr">Less</a>
                </template>
              </span>
            </el-tag>
          </div>
          <el-card v-show="field.isMapping" style="width: 100%">
            <template v-slot:header>
              <div class="clearfix">
                <span>Select Map Fields</span>
                <el-button style="float: right" type="text" @click="hideCard(field)"
                  >X</el-button
                >
              </div>
            </template>

            <el-collapse v-model="accordion1Expanded">
              <el-collapse-item v-for="(fields, index) in mappingFields" :key="index">
                <template #title>
                  <img :src="fields.logo" alt="logo" class="button-icon" />
                  {{ fields.app_name }}
                </template>

                <div class="button-row">
                  <el-button
                    v-for="(item, index) in fields.fields"
                    :key="index"
                    class="button-with-icon"
                    @click="toggleTag(item, field)"
                  >
                    <img :src="fields.logo" alt="logo" class="button-icon" />
                    <template v-if="!item.showFullText">
                      <span class="clrf">{{ item.label }}</span> :
                      <span class="clrl">
                        {{
                          item.value && item.value.length > 25
                            ? item.value.slice(0, 25) + "..."
                            : item.value
                        }}
                      </span>
                      <span v-if="item.value && item.value.length > 25">
                        <a @click="toggleTextFortag(item)" class="clr">More</a>
                      </span>
                    </template>
                    <template v-else>
                      <span class="clrf">{{ item.label }}</span> :
                      <span class="clrl">{{ item.value }}</span>
                      <a @click="toggleTextFortag(item)" class="clr">Less</a>
                    </template>
                  </el-button>
                </div>
              </el-collapse-item>
            </el-collapse>
          </el-card>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import renderFields from "@/components/connectorApp/renderFields";
import store from "@/store/index";

export default {
  components: {
    renderFields,
  },
  props: {
    fields: {
      type: Array,
      required: true,
    },
    mappingFields: {
      type: Array,
    },
    actionInfo: {
      type: Array,
    },
    automationInfo: {
      type: Array,
      required: true,
    },
    isTrigger: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      accordion1Expanded: false,
      allFields: null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters("globalApis", ["getNestedAppFields", "getDependencyFields"]),
  },
  mounted() {
    this.allFields = this.fields;
    console.log("this.allFields", this.allFields);
  },
  methods: {
    showCard(field) {
      if (!this.isTrigger) {
        field.isMapping = true;
        this.accordion1Expanded = true;
      }
    },
    hideCard(field) {
      field.isMapping = false;
    },
    toggleTag(tag, field) {
      field.mappedFields.push(tag);
    },
    toggleText(tag) {
      tag.showFullText = !tag.showFullText;
    },
    removeTag(index, field) {
      field.mappedFields.splice(index, 1);
    },
    addRow(field) {
      const newRow = field.array_fields.map((col) => ({
        ...col,
        model: null,
        mappedFields: [],
      }));
      if (!field.rows) {
        this.$set(field, "rows", []);
      }
      field.rows.push(newRow);
    },
    removeRow(field, rowIndex) {
      field.rows.splice(rowIndex, 1);
    },
    async fillDepencies(field, fieldsSet) {
      let params;
      if (this.isTrigger) {
        let matchedEvent = await this.automationInfo.app_id.trigger_events.find(
          (event) => event._id == this.automationInfo.event_id
        );
        params = {
          appName: this.automationInfo.app_id.app_name_key,
          automationId: this.automationInfo._id,
          connectionId: this.automationInfo.app_connection_id,
          fieldsSet: fieldsSet,
          field: field,
          eventName: matchedEvent.entity,
          isTrigger: this.isTrigger,
        };
      } else {
        let matchedEvent = await this.actionInfo.app_id.action_events.find(
          (event) => event._id == this.actionInfo.event_id
        );
        params = {
          appName: this.actionInfo.app_id.app_name_key,
          automationId: this.automationInfo._id,
          connectionId: this.actionInfo.app_connection_id,
          fieldsSet: fieldsSet,
          field: field,
          eventName: matchedEvent.entity,
          isTrigger: this.isTrigger,
        };
      }
      this.loading = true;
      await store.dispatch("globalApis/fillDependencyValues", params);
      this.loading = false;
      if (this.getDependencyFields.data) {
        this.allFields = this.getDependencyFields.data;
        this.bindDepencyFieldsInParent();
      }
    },
    async bindDepencyFieldsInParent() {
      this.emitter.emit("bindDepencyFieldsInParent", this.allFields);
    },
    async handleDependecyFields(field, fieldsSet) {
      await this.fillDepencies(field, fieldsSet);
    },
  },
};
</script>

<style scoped>
.artboard {
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap !important;
  overflow-y: auto;
  max-height: 140px;
}

.tag-textarea {
  width: calc(100% - 2px);
  /* border: 1px solid #ccc; */
  border-radius: 4px;
  padding: 8px;
  resize: vertical;
}

.button-with-icon {
  display: flex;
  align-items: center;

  background-color: rgb(255, 255, 255);
  border: 1px solid #684fa9;
  box-shadow: rgb(149, 146, 142) 0px 0px 0px 1px;
  border-radius: 5px;
  cursor: pointer;
  padding: 15px;
  white-space: nowrap;
}

.button-with-icon:hover {
  display: flex;
  align-items: center;

  border: 1px solid #684fa9;
  background-color: rgb(232, 231, 228);
  border-radius: 3px;
  box-shadow: rgb(149, 146, 142) 0px 0px 0px 1px;
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  /* margin-top: 8px; */
  overflow-y: auto;

  width: 100%;
}

.tags-container .el-tag {
  margin-right: 8px;
  margin-bottom: 8px;
}

.button-row {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  overflow-y: auto;
  max-height: 160px;
}

.button-icon {
  height: 18px !important;
  width: 20px !important;
  margin-right: 5px !important;
}

.clrl {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #909399;
}
</style>
