export default {
  isAuthenticated() {
    try {
      let userInfo = JSON.parse(localStorage.getItem("user-info"));
      if (userInfo && userInfo.tokens) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log("error in middleware", error);
    }
  },
};
