// import {mapGetters} from 'vuex';

export default {
  data() {
    return {};
  },
  computed: {},
  methods: {
    truncateText(text, maxLength) {
      if (text && text.length > maxLength) {
        return text.slice(0, maxLength) + "...";
      }
      return text;
    },
    formatDate(timestamp) {
      const date = new Date(timestamp);
      const options = {
        day: "2-digit",
        month: "short",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      };
      return date.toLocaleString("en-US", options);
    },
    getLocalTime(utcTime) {
      const timeZoneOffset = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const localTime = new Date(utcTime);
      const options = {
        timeZone: timeZoneOffset,
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false, // Set to false for 24-hour format
      };
      const formattedTime = localTime
        .toLocaleString("en-US", options)
        .replace(/\//g, "-"); // Remove the comma
      return formattedTime;
    },
  },
};
